html {
    min-height: 100%;
}

body {
    height: 100vh;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.homepage {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .img-logo {
        background: url('../assets/CPFULLLOGO.png') no-repeat center 100% / 100%;
        background-size: contain;
        margin: 0 auto 50px;
        width: 100%;
        max-width: 350px;
        height: 120px;
    }

    .welcome-msg {
        font-size: 20px;
    }
}

.selects-container {
    width: 100%;
    max-width: 400px;

    .MuiFormLabel-root {
        margin-left: 10px;
    }
}

.chip-container {
    padding: 0px 12px;
    margin-bottom: 15px;
}

.MuiDialog-paperWidthSm {
    width: 100%;
}

.MuiButton-text {
    border: 1px solid !important;
}

iframe {
    border: 0;
}

.MuiAppBar-colorPrimary {
    background-color: #63b5d6 !important;
}

.note-title {
    margin-right: 10px;
    display: inline;
    font-size: 16px;
    font-weight: bold;
}

.fullscreen-logo {
    background: url('../assets/rounded-logo.png') no-repeat center 100% / 100%;
    background-size: contain;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: 20px;
}
