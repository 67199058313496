.ql-editor {
    overflow-y: scroll;
    resize: vertical;
    min-height: 250px;
}

.action-item {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.action-item:active {
    color: deeppink;
}
